import React, { useState } from "react"
import { withRouter } from "react-router"

import InputArea from "./InputArea"

function Edit(props) {
  const [journal, setJournal] = useState(props.journals.find(journal => journal.nanoid === props.match.params.id))

  function updateJournal() {
    props.onEdit(journal)
    props.history.push("/")
  }

  return (
    <div>
      <InputArea
        journal={journal}
        setJournal={setJournal}
        editing={true}
        onSubmit={updateJournal}
      />
    </div>
  )
}

export default withRouter(Edit)