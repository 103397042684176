import React, { useState } from "react"
import {nanoid} from "nanoid"

import InputArea from "./InputArea"

function Create(props) {
  const [editing, setEditing] = useState(false)
  const [journal, setJournal] = useState({
    title: "",
    content: "",
    feels: "😊",
    nanoid: nanoid()
  })

  function submitJournal() {   
    props.onAdd(journal)

    setEditing(false)

    setJournal({
      title: "",
      content: "",
      feels: "😊",
      nanoid: nanoid()
    })
  }

  return (
    <div onMouseOver={() => setEditing(true)} onMouseLeave={() => setEditing(false)}>
      <InputArea
        journal={journal}
        setJournal={setJournal}
        editing={editing}
        onSubmit={submitJournal}
      />
    </div>
  )
}

export default Create
