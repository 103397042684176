import React from "react"
import { Link } from "react-router-dom"
import { Edit, DeleteForever } from "@material-ui/icons"

function Journal(props) {

  return (
    <div className="journal">
      <h1>{props.title.length > 30 ? props.title.substring(0, 30) : props.title}</h1>
      <p>{props.content}</p>
      <p>{props.feels}</p>
      <Link
        className="journal-button edit"
        to={"/edit/" + props.id}>
        <Edit />
      </Link>
      <Link
        className="journal-button delete"
        to={""}
        onClick={() => props.onDelete(props.id)}>
        <DeleteForever />
      </Link>
    </div>
  )
}

export default Journal
