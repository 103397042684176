import React, { useState, useRef } from "react"
import { Add } from "@material-ui/icons"
import { Fab } from "@material-ui/core"
import { Zoom } from "@material-ui/core"

function InputArea(props) {
    const [focusing, setFocusing] = useState(false)
    const inputRef = useRef(null)

    function handleChange(event) {
        const { name, value } = event.target

        props.setJournal(prevJournal => {
            return {
                ...prevJournal,
                [name]: value
            }
        })
    }

    function handleMouseOver() {
        !focusing && inputRef.current.focus()
        setFocusing(true)
    }

    function handleClick() {
        props.onSubmit()
        setFocusing(false)
    }

    return (
        <form onMouseOver={handleMouseOver} className="create-journal">
            {props.editing &&
                <input
                    className="inputTitle"
                    name="title"
                    onChange={handleChange}
                    value={props.journal.title}
                    placeholder="Title"
                />
            }

            <textarea
                className="inputContent"
                name="content"
                ref={inputRef}
                onChange={handleChange}
                value={props.journal.content}
                placeholder="Take a note..."
                rows={props.editing ? 3 : 1}
            />

            {props.editing &&
                <div className="feels-group">
                    <input
                        className="feels-button"
                        type="radio"
                        name="feels"
                        id="😊"
                        value="😊"
                        checked={props.journal.feels === "😊"}
                        onChange={handleChange}
                    />
                    <label className="feels-label">I'm 😊</label>
                    <input
                        className="feels-button"
                        type="radio"
                        name="feels"
                        id="😔"
                        value="😔"
                        checked={props.journal.feels === "😔"}
                        onChange={handleChange}
                    />
                    <label className="feels-label">I'm 😔</label>
                    <input
                        className="feels-button"
                        type="radio"
                        name="feels"
                        id="💭"
                        value="💭"
                        checked={props.journal.feels === "💭"}
                        onChange={handleChange}
                    />
                    <label className="feels-label">Free 💭</label>
                </div>
            }

            <Zoom in={props.editing}>
                <Fab onClick={handleClick}><Add />
                </Fab>
            </Zoom>
        </form>

    )
}

export default InputArea