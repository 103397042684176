const sampleJournals = [
    {
        "title": "To be, or not to be",
        "content": "That is the question: Whether ’tis nobler in the mind to suffer \nThe slings and arrows of outrageous fortune.\nThe undiscovered country, from whose bourn\nNo traveller returns, puzzles the will,\nAnd makes us rather bear those ills we have,\nThan fly to others that we know not of?",
        "feels": "💭",
        "nanoid": "1123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
    },
    {
        "title": "This above all",
        "content": "To thine own self be true, and it must follow, \nThou canst not then be false to any man.",
        "feels": "😊",
        "nanoid": "2123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
    },
    {
        "title": "Et tu",
        "content": "Brute?",
        "feels": "😊",
        "nanoid": "xD0pOzsDpNNj-lgTzwyMO"
    },
    {
        "title": "Love looks not with the eyes",
        "content": "But with the mind,\nAnd therefore is winged Cupid painted blind.",
        "feels": "💭",
        "nanoid": "vw_sxbt9gxAMH7YSlwef8"
    },
    {
        "title": "Sample Note",
        "content": "Today was a good day!",
        "feels": "😊",
        "nanoid": "Fv09zZNBKy1kP2qyccJMr"
    },
    {
        "title": "The fault",
        "content": "Dear Brutus, is not in our stars, but in ourselves.",
        "feels": "😔",
        "nanoid": "zsdXFdn9zXYvgSNXr-vRj"
    },
    {
        "title": "Parting is such sweet sorrow",
        "content": "That I shall say good night till it be morrow.",
        "feels": "😔",
        "nanoid": "Acbi0Djxb9lUhUpmPk8V-"
    },
    {
        "title": "The beauty of the world",
        "content": "The paragon of animals. And yet, to me, what is this quintessence of dust?",
        "feels": "💭",
        "nanoid": "9Kwqb27BGHE1CHJR3JN07"
    },
    {
        "title": "Be great in act",
        "content": "As you have been in thought.",
        "feels": "😊",
        "nanoid": "01AtvjHdZAT7MqAczmDbV"
    }
]

export default sampleJournals