import React from "react"
import { Link } from "react-router-dom"
import { HighlightSharp } from "@material-ui/icons"

function Header() {
  return (
    <header>
      <Link className="navbar-brand" to={"/"}>
        <h1>
          <HighlightSharp />
          Journal
        </h1>
      </Link>
    </header>
  )
}

export default Header
