import React, { useState, useEffect } from "react"

import { Route } from "react-router-dom"
import Masonry from "@mui/lab/Masonry";
import "bootstrap/dist/css/bootstrap.css"

import Header from "./components/Header"
import Journal from "./components/Journal"
import Create from "./components/Create"
import Edit from "./components/Edit"
import sampleJournals from "./data/sampleJournals"

function App() {
  const [journals, setJournals] = useState(JSON.parse(localStorage.getItem("storedJournals")))

  useEffect(() => {
    if (!journals) {
      setJournals(sampleJournals)
    }
    else {
      localStorage.setItem("storedJournals", JSON.stringify(journals))
    }
  }, [journals])

  function addJournal(newJournal) {
    setJournals(prevJournals => {
      return [...prevJournals, newJournal]
    })
  }

  function editJournal(editedJournal) {
    setJournals(journals.map(journal => {
      if (journal.nanoid === editedJournal.nanoid) {
        return editedJournal
      } else {
        return journal
      }
    }))
  }

  function deleteJournal(id) {
    setJournals(journals.filter((journal) => journal.nanoid !== id))
  }

  return (
    <div>
      <Header />
      <Route exact path="/">
        <div className="create-container">
          <Create onAdd={addJournal} />
        </div>
        <div className="journal-container">
          <Masonry columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} spacing={2}>
            {journals?.map(journal => (
              <Journal
                key={journal.nanoid}
                id={journal.nanoid}
                title={journal.title}
                content={journal.content}
                feels={journal.feels}
                onDelete={deleteJournal}
              />
            ))}
          </Masonry>
        </div>
      </Route>
      <Route path="/edit/:id">
        <Edit onEdit={editJournal} journals={journals} />
      </Route>
    </div>
  )
}

export default App